<template>
  <div class="tc-training_statistics">
    <!-- 头部功能 -->
    <!-- <div class="header">
      <div class="header-operation">
        <ul>
          <li>
            <img src="../../../assets/images/user_information_print.png" alt />
          </li>
          <li>
            <img src="../../../assets/images/user_information_set.png" alt />
          </li>
          <li>
            <img src="../../../assets/images/user_information_batch.png" alt />
          </li>
          <li>
            <img src="../../../assets/images/user_information_query.png" alt />
          </li>
        </ul>
      </div>
      <div class="header-tool">
        <div class="del">
          <img src="../../../assets/images/del.png" alt />
        </div>
        <div class="refresh" @click="refresh()">
          <img src="../../../assets/images/refresh.png" alt />
        </div>
      </div>
    </div>-->
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="门诊号/住院号" prop="account">
            <el-input v-model="formInline.account"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="uName">
            <el-input v-model="formInline.uName"></el-input>
          </el-form-item>
          <el-form-item label="科室名称" prop="deptName">
            <el-input v-model="formInline.deptName"></el-input>
          </el-form-item>
          <el-form-item label="游戏名称" prop="gameName">
            <el-input v-model="formInline.gameName"></el-input>
          </el-form-item>
          <el-form-item label="训练时间范围" prop="date">
            <el-date-picker
              v-model="formInline.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="onSubmit()">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <!-- <el-table-column type="selection" width="60" align="center"></el-table-column> -->
        <el-table-column
          prop="user_account"
          label="门诊号/住院号"
          width="250"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column prop="user_name" label="姓名" width="200" align="center"></el-table-column>
        <el-table-column prop="department_name" label="科室名称" width="250" align="center"></el-table-column>
        <el-table-column prop="title" label="游戏名称" width="200" align="center"></el-table-column>
        <el-table-column prop="create_time" label="最后训练时间" width="250" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="viewUserGameReport(scope.row)">查看</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="delReport(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="size"
        layout="total,prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { listUserGame, delUserGame } from "@/api/game.js";
export default {
  data() {
    return {
      formInline: {
        date: [],
        account: "",
        gameName: "",
        uName: "",
        account: "",
        deptName: "",
      },
      tableData: [],
      // 页码数据
      page: 1,
      size: 20,
      total: 0,
    };
  },
  created() {
    this.loadListData();
  },
  methods: {
    // 重置
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    handleSelectionChange() {},
    handleCurrentChange(val) {
      this.page = val;
      this.loadListData();
    },
    // 加载列表数据
    loadListData() {
      let data = {
        page: this.page,
        size: this.size,
        condition: {
          "%-g.title": this.formInline.gameName,
          "%-d.name": this.formInline.deptName,
          "%-u.real_name": this.formInline.uName,
          "%-u.account": this.formInline.account,
        },
      };
      if (this.formInline.date.length > 1) {
        data.begin_time = this.formInline.date[0];
        data.end_time = this.formInline.date[1];
      }
      listUserGame(data).then((res) => {
        if (res.code == 400200) {
          this.tableData = res.data;
          this.total = res.count * 1;
        }
      });
    },
    // 查询
    onSubmit() {
      this.loadListData();
    },
    // 用户报告查看
    viewUserGameReport(row) {
      this.$router.push(`/training_statistics/gameReport?id=${row.id}`);
    },
    // 刪除報告
    delReport(row) {
      let id = "";
      if (row.id) {
        id = row.id;
      }
      let data = {
        id,
      };
      delUserGame(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
          this.loadListData();
        }
      });
    },
  },
};
</script>
<style lang="less">
.tc-training_statistics {
  // 头部
  .header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
    justify-content: center;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 150px;
          list-style: none;
          padding: 0 30px;
          text-align: center;
          img {
            width: 100%;
            // pointer-events: none;
          }
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .del {
        width: 120px;
        cursor: pointer;
      }
      .refresh {
        width: 120px;
        cursor: pointer;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(5) .el-input__inner {
        width: 230px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 580px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 页码
  .page {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>