import request from '@/utils/request'



// ---------------独立游戏----------------------

// 获取独立游戏列表
export function listGame(data) {
    return request({
        url: '/list_game',
        method: 'get',
        params: data
    })
}
// 删除独立游戏
export function delGame(data) {
    return request({
        url: '/del_game',
        method: 'get',
        params: data,
    })
}

// 添加 / 修改  独立游戏
export function saveGame(data) {
    return request({
        url: '/save_game',
        method: 'post',
        data
    })
}

// 添加游戏类型 、 --------添加 、 修改 组合游戏
export function saveGameClassify(data) {
    return request({
        url: '/save_game_classify',
        method: 'post',
        data
    })
}

// ------------组合游戏包-------------

// 获取独立游戏列表
export function listGameClassify(data) {
    return request({
        url: '/list_game_classify',
        method: 'get',
        params: data
    })
}
// 删除组、分类
export function delGameClassify(data) {
    return request({
        url: '/del_game_classify',
        method: 'get',
        params: data
    })
}


// --------------游戏报告管理----------------


// 获取 游戏报告列表
export function listUserGame(data) {
    return request({
        url: '/list_user_game',
        method: 'get',
        params: data
    })
}
// 获取 游戲報告詳情
export function gameResult(data) {
    return request({
        url: '/game_result',
        method: 'get',
        params: data
    })
}

// 删除游戏报告
export function delUserGame(data) {
    return request({
        url: '/del_user_game',
        method: 'get',
        params: data
    })
}
// --------------游戏和音乐----------------

// 获取音乐
export function getmusic(data) {
    return request({
        url: '/getmusic',
        method: 'get',
        params: data
    })
}

//分配音乐和游戏
export function distributionsorce(data) {
    return request({
        url: '/distributionsorce',
        method: 'post',
        data
    })
}

// 删除分配的音乐和游戏
export function deldistributionsorce(data) {
    return request({
        url: '/deldistributionsorce',
        method: 'delete',
        params: data
    })
}

// 获取分配的音乐和游戏
export function getdistributionsorce(data) {
    return request({
        url: '/getdistributionsorce',
        method: 'get',
        params: data
    })
}